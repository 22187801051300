import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/src/cookieconsent.js";

var cc = initCookieConsent();
cc.run({
    current_lang: document.documentElement.getAttribute("lang"),
    mode: "opt-out",

    gui_options: {
        consent_modal: {
            layout: "box",
            position: "bottom left",
        },
    },

    languages: {
        bg: {
            consent_modal: {
                title: "Използваме бисквитки!",
                description:
                    'Този уебсайт използва бисквитки, за да гарантира правилното му функциониране и проследяващи бисквитки, за да разбере как взаимодействате с него. <button type="button" data-cc="c-settings" class="cc-link">Остави ме да избера</button>',
                primary_btn: {
                    text: "Приеми всички",
                    role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "Откажи всички",
                    role: "accept_necessary", // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: "Настройки на бисквитките",
                save_settings_btn: "Запиши настройките",
                accept_all_btn: "Приеми всички",
                reject_all_btn: "Откажи всички",
                close_btn_label: "Затвори",
                cookie_table_headers: [
                    { col1: "Name" },
                    { col2: "Domain" },
                    { col3: "Expiration" },
                    { col4: "Description" },
                ],
                blocks: [
                    {
                        title: "Използване на бисквитки",
                        description:
                            'Използвам бисквитки, за да гарантирам основните функции на уебсайта и да подобря вашето онлайн изживяване. Можете да изберете за от данните за всяка една категория. За повече подробности относно бисквитките и други чувствителни данни, моля, прочетете пълната <a href="https://www.intellect.bg/zadalzhitelna-informatsia-za-pravata-na-litsata-po-zashtita-na-lichnite-danni" class="cc-link">декларация за поверителност</a>.',
                    },
                    {
                        title: "Необходими за работата бисквитки",
                        description:
                            "Тези бисквитки са от съществено значение за правилното функциониране на моя уебсайт. Без тези бисквитки уебсайтът няма да работи правилно.",
                        toggle: {
                            value: "necessary",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Бисквитки за анализ",
                        description:
                            "Тези бисквитки позволяват на уебсайта да запомни изборите, които сте направили в миналото.",
                        toggle: {
                            value: "analytics", // your cookie category
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: "^_ga", // match all cookies starting with "_ga"
                                col2: "google.com",
                                col3: "2 years",
                                col4: "",
                                is_regex: true,
                            },
                            {
                                col1: "_gid",
                                col2: "google.com",
                                col3: "1 day",
                                col4: "",
                            },
                        ],
                    },
                    {
                        title: "Рекламни и таргетиращи бисквитки",
                        description:
                            "Тези бисквитки събират информация за това как използвате този уебсайт, кои страници сте посетили и как сте взаимодействали с него. Всички данни са анонимизирани и не могат да бъдат използвани за идентифициране на Вашата самоличност.",
                        toggle: {
                            value: "targeting",
                            enabled: false,
                            readonly: false,
                        },
                    },
                    {
                        title: "Допълнителна информация",
                        description:
                            'За всякакви въпроси във връзка с нашите политики, моля <a class="cc-link" href="#">свържете се с нас на info@intellect.bg или 0700 13 002</a>.',
                    },
                ],
            },
        },

        en: {
            consent_modal: {
                title: "We are using cookies!",
                description:
                    'This website uses cookies to ensure it functions properly and tracking cookies to understand how you interact with it. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: "Accept All",
                    role: "accept_all", // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: "Accept Necessary",
                    role: "accept_necessary", // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: "Cookie settings",
                save_settings_btn: "Save settings",
                accept_all_btn: "Accept All",
                reject_all_btn: "Reject All",
                close_btn_label: "Close",
                cookie_table_headers: [
                    { col1: "Name" },
                    { col2: "Domain" },
                    { col3: "Expiration" },
                    { col4: "Description" },
                ],
                blocks: [
                    {
                        title: "Use of cookies",
                        description:
                            'I use cookies to ensure the basic functions of the website and to improve your online experience. You can opt in for from the data for each category. For more details about cookies and other sensitive data, please read the full <a href="https://www.intellect.bg/zadalzhitelna-informatsia-za-pravata-na-litsata-po-zashtita-na-lichnite-danni" class="cc-link">privacy statement</a>.',
                    },
                    {
                        title: "Cookies required for operation",
                        description:
                            "These cookies are essential for the proper functioning of my website. Without these cookies the website will not work properly.",
                        toggle: {
                            value: "necessary",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Analytics cookies",
                        description:
                            "These cookies allow the website to remember choices you have made in the past.",
                        toggle: {
                            value: "analytics", // your cookie category
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: "^_ga", // match all cookies starting with "_ga"
                                col2: "google.com",
                                col3: "2 years",
                                col4: "",
                                is_regex: true,
                            },
                            {
                                col1: "_gid",
                                col2: "google.com",
                                col3: "1 day",
                                col4: "",
                            },
                        ],
                    },
                    {
                        title: "Advertising and targeting cookies",
                        description:
                            "These cookies collect information about how you use this website, which pages you have visited and how you have interacted with it. All data is anonymised and cannot be used to identify you.",
                        toggle: {
                            value: "targeting",
                            enabled: false,
                            readonly: false,
                        },
                    },
                    {
                        title: "Additional information",
                        description:
                            'For any questions regarding our policies, please <a class="cc-link" href="#">contact us at info@intellect.bg or 0700 13 002</a>.',
                    },
                ],
            },
        },
    },
});
